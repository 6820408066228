/* Table Styles */
.customTable {
    width: unset !important;
    table-layout: fixed !important; /* Control column sizes */
}

.squareSpan {
    display: inline-block;
    width: 32px;
    height: 32px;
    
}

.customTable .headerCell {
    background-color: var(--header-2);
    text-align: left;
    font-weight: bold;
    color: white;
    white-space: nowrap;
    font-size: 1rem;
    padding-right: 10px;
}

.customTable .deleteCell {
    white-space: nowrap;
    text-align: center;
    width: 1%; /* Shrink to content */
}

.customTable .qtyCell {
    white-space: nowrap;
    text-align: center;
    width: 1%; /* Shrink to content */
}

.customTable .nameCell {
    white-space: nowrap;
    text-align: left;
    min-width: 1%; /* Allocate 60% of width for Name */
}

.customTable .descriptionCell {
    flex: 1;
    text-align: left;
    overflow-wrap: break-word;
    word-wrap: break-word;
    max-width: 1%; /* Restrict overflow */
}

.customTable .filesCell {
    white-space: nowrap;
    text-align: center;
    min-width: 1%; /* Shrink to content */
}
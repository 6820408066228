.container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  font-size: 18px;
  padding: 1em;
  width: 32em;
  overflow-wrap: break-word;
}

.container:hover {
  background-color: var(--primaryColor);
  color: white;
}

.container:last-child {
  border-bottom: none;
}

.imageHover {
    color: var(--primaryColor);
}

.imageHover:hover {
    cursor: zoom-in;
    font-weight: bold;
}

.MuiTooltip-tooltip {
    background: transparent;
}

.orderRow:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.downloadLink {
    color: var(--primaryColor);
    text-decoration: underline;
    cursor: pointer;
}

.downloadLink:hover {
    font-weight: bold;
}
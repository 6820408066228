/* AddTool.css (create a new CSS file if needed) */
/* Modal background overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top of other content */
}

/* Modal content */
.modal-content {
  background-color: #fff; /* White background for the modal */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for a slight elevation effect */
  max-width: 400px; /* Adjust the maximum width as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  .modal-content div {
    margin-bottom: 4px;
  }
.input__container {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.input__container:focus-within {
  outline: 1px dashed #c2c2c2;
  outline-offset: -1px;
}

.input__task,
.input__description {
  border: none;
  outline: none;
  padding: 2px;
  background-color: transparent;
  width: 6.5rem;
  box-sizing: border-box;
}

.input__task {
  text-transform: uppercase;
  font-weight: bold;
}

.input__description {
  font-size: 0.75rem;
  color: grey;
}

.input__task:focus,
.input__description:focus {
  outline: 1px dashed #c2c2c2;
  outline-offset: -1px;
}

.input__row {
  width: 2rem;
  /* outline: 1px solid #c2c2c2; */
  font-weight: bold;
  border: none;
  outline: none;
  outline-offset: -1px;
  text-align: right;
  background-color: transparent;
}

.input__row:focus-within {
  outline: 1px dashed #c2c2c2;
  outline-offset: -1px;
}

.input__row_text {
  font-size: 0.7rem;
}

.input__row_comment {
  width: 10rem;
  outline: 1px solid #c2c2c2;
  outline-offset: -1px;
}

.pd_0 {
  padding: 0;
}

.pd_tp__05 {
  padding-top: 0.5rem;
}

.add_button {
  color: #000;
  background-color: burlywood;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Mozilla Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-wrapper button {
  position: absolute;
  right: 0; /* Adjust as needed */
  /* Additional styles for the button */
}

.button {
  background-color: var(--greyTaskBtn);
  border: none;
  text-transform: uppercase;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  transition: filter 0.3s ease;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3rem;
}

.title {
  padding-left: 0.4rem;
}

.button:hover {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  offset: 0.5;
}

.button {
  border: none;
  cursor: pointer;
}

.todo {
  background-color: var(--black25);
}

.active {
  background-color: var(--lightMain);
}

.done {
  background-color: var(--darkGreen);
}

/* MachineModal.module.css */
.openModalButton {
  background-color: #7fc5bf;
  color: black;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 9rem;
  line-height: 2.4;
}

.openModalButton:hover {
  background-color: transparent;
  color: #7fc5bf;
  border: 1px solid #7fc5bf;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  max-width: 90%;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 24px;
  line-height: 1;
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
}

.buttonContainer {
  white-space: nowrap;
}

.decreaseButton,
.increaseButton {
  min-width: unset;
}

.quantityText {
  top: 0.1em;
  margin: 0 0.2em;
  border-radius: 4px;
  width: 3em;
  height: 2.1em;
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  background: inherit;
}

.quantityText:hover,
.quantityText:focus {
  background: #eee;
}

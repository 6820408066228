.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.modalOpen {
  opacity: 1;
  pointer-events: auto;
}

.modalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  background-color: #fff;
  max-height: 80%;
  border-radius: 8px;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

@media (max-width: 768px) {
  .modalContent {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}

.modalContentTasks {
  border-radius: 0px;
  padding: 0px;
}

.previewImage {
  width: 150px;
  display: inline-block;
  background-color: #ccc;
}

@media screen and (max-width: 1000px) {
  .previewImage {
    height: 36px;
    width: 54px;
  }
}

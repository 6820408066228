.borderlessSelect {
  border: none;
  color: #007c7c;
  font-weight: bold;

  border-radius: 0;
  -webkit-border-radius: 0;
  cursor: pointer;
  background: transparent;
}

.borderlessSelect:focus-visible {
  border: 1px solid transparent;
  outline: none;
}

.borderlessSelect:disabled {
  color: #c4c4c4;
  cursor: not-allowed;
}
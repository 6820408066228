.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.container {
  border: 2px solid #075e5b;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  margin-right: 10px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}

.container__checked {
  background-color: #075e5b;
}

.container__unchecked {
  background-color: #fff;
}

.input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.check {
  color: #fff;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: row;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.pdRg {
  padding-right: 0.5rem;
}

.smallTitle {
  font-size: 0.5rem;
}

.algnCenter {
  display: flex;
  align-items: center;
}

.certificate {
  fill: var(--mainYellow) !important;
}

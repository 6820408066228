.container {
  max-width: calc(1920px - 240px);
}

@media only screen and (max-width: 1100px) {
  .container {
    width: 1100px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 600px;
  }
}

.item:hover {
  background-color: var(--header);
}

.quantitySelector {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 0.2rem;
  padding-left: 0.5rem;
}

.decrease_button {
  background-color: #e12c4f;
}

.increase_button {
  background-color: #028b81;
}

.decrease_button,
.increase_button {
  color: #fff;
  border: none;
  border-radius: 3px;
  width: 20px;
  height: 13px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increase_button__container {
  padding-bottom: 0.1rem;
}

.inputGroup {
  display: flex;
  align-items: center;
}

.quantityInput,
.maxQuantityInput {
  max-width: 2.5rem;
  border-radius: 3px;
  border: 1px solid #c2c2c2;
  text-align: right;
  padding: 0.2rem;
}

.separator {
  padding: 0 0.2rem;
}

.searchContainer {
  position: relative;
  width: 200px;
}

.dropdown {
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  color: black;
  padding: 0;
  max-height: 375px;
  overflow-y: scroll;
  width: 210px;
}

.dropdownItem {
  padding: 6px;
  cursor: pointer;
  list-style-type: none;
}

.dropdownItem:hover {
  background-color: #075e5b47;
}

.dropdownItemLoader {
  padding: 4px;
  cursor: pointer;
  list-style-type: none;
}

.dropdownNoItem {
  height: 375px;
  width: 189px;
}

.noItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10rem;
  font-weight: bold;
  color: var(--black70);
}

.textInput {
  width: 100%;
}

.orderId {
  font-weight: 700;
}

.orderItems {
  font-size: 0.8rem;
}

.flexC {
  display: flex;
  flex-direction: column;
}

.flexR {
  display: flex;
  flex-direction: row;
}

.lineHeight {
  line-height: 0.85;
}

.orderName {
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  font-size: 0.8rem;
}

.companyName {
  padding-left: 0.3rem;
  font-size: 0.7rem;
}

.textInput {
  background-color: white;
}

.pd05 {
  padding: 0.4rem;
}

.pdL05 {
  padding-left: 0.5rem;
}

.selected {
  background-color: var(--lightMain);
}

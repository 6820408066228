.header__container {
  height: 6vh;
  background: var(--header);
  color: #fff;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.header__text {
  width: 100%;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  padding-left: 1rem;
}

.button__container {
  float: right;
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
}

.button {
  padding-right: 1rem;
}

/* All three divs */
.overview__container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

/* First div */
.details__container {
  width: 22vw;
}

.details__titel {
  color: white;
  background: #007474;
  padding: 0.2rem 0 0.2rem 1rem;
  font-weight: 700;
  font-size: 18px;
}

.details__container_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.details__column_author {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem 0.8rem 0.2rem;
}

.details__column_order {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.4rem 0.2rem;
}

.details__column_currency {
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0 0 0rem;
}

.details__column_account {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.8rem 0;
}

.details__column_user {
  display: flex;
  flex-direction: column;
  padding: 0 0 0.8rem 0;
}

.details__column_shipping {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.8rem 0;
}

.details__column_state {
  display: flex;
  flex-direction: column;
}

/* 2nd div */
.shipping__container {
  width: 20vw;
}

.shipping__title {
  color: white;
  background: #007474;
  padding: 0.2rem 0 0.2rem 1rem;
  font-weight: 700;
  font-size: 18px;
}

.shipping__container_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
}

.shipping__column_type {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem 0.8rem 0.2rem;
}

.shipping__column_company {
  display: flex;
  flex-direction: column;
  padding: 0 0 0.8rem 0.2rem;
}

.shipping__column_email {
  display: flex;
  flex-direction: column;
  padding: 0 0 0.8rem 0.2rem;
}

.shipping__column_address {
  display: flex;
  flex-direction: column;
  padding: 0 0 0.8rem 0.2rem;
}

.shipping__column_shipment {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.8rem 0;
}

/* 3rd div */
.payment__container {
  width: 21vw;
}

.payment__title {
  color: white;
  background: #007474;
  padding: 0.2rem 0 0.2rem 1rem;
  font-weight: 700;
  font-size: 18px;
}

.payment__container_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
}

.payment__column_type {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem 0.8rem 0.2rem;
}

.table__header {
  background: #007474;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
}

.fileInputLabel {
  display: inline-block;
  position: relative;
}

.fileInputLabel input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.fileInputButton {
  display: inline-block;
  background-color: #3f51b5;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fileInputButton:hover {
  background-color: #2c387e;
}

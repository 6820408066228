.container {
  position: relative;
  /* border: 1px solid #d0d0d0;
  border-radius: 5px; */
  height: 99%;
  width: 99%;
}

.canvasContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}

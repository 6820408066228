.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(var(--cardBackground), 0.87);
  background: #e9f5f3 !important;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(var(--blackColorRGB), 0.14) !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  font-size: 0.875rem;
}

.inputAdornment {
  margin-right: 18px;
}

.inputAdornmentIcon {
  color: var(--grayColor);
}

.number input::-webkit-outer-spin-button,
.number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.listIcon {
  color: white;
  margin-left: 0.2em;
}

.activeLink {
  background: var(--primaryColor);
}

.container {

  /*overscroll-behavior: none;*/
}

.container::-webkit-scrollbar {
  width: 2%;
}

.container::-webkit-scrollbar-track {
  background: #282c34;
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--primaryColor);
}

.container {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.4rem;
  border-radius: 1px;
  min-width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #fff;
  text-transform: uppercase;
  font-size: 0.85rem;
  color: #848585;
  font-weight: bold;
}

.containerClickable {
  cursor: pointer;
}

.containerClickable:hover {
  background: #f8edcc;
}

.active {
  background: var(--buttonYellow);
  color: var(--white);
}

.active:hover {
  color: #848585;
}

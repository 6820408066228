.dialogContent {
  display: flex;
  overflow-y: hidden;
}

.tabContent {
  flex: 1;
}

.model_div {
  overflow: visible;
  height: 100%;
  width: 100%;
}

.model_dimensions {
  font-size: 0.9em;
  color: #808080;
  padding: 0.9em 0em 1em 2em;
  position: absolute;
  bottom: 1em;
  left: 1em;
}

.extra_info_div {
  display: flex;
  float: right;
  justify-content: space-between;
  flex: none;
  max-height: 100%;
}

.productId {
  font-weight: bold;
  color: black;
  padding: 0px 2px 10px 5px;
}

.email {
  padding: 0px 2px 0px 5px;
}

.product_created {
  padding: 0px 2px 10px 5px;
  font-size: 0.7rem;
}

.image_div {
  width: 12vw;
  height: 12vh;
  padding: 3px 10px 20px 5px;
}

.typography_info_fileDownload {
  display: flex;
  justify-content: center;
  padding: 15px 10px 10px 5px;
  font-size: 0.7rem;
}

.typography_info_fileDownload__button {
  display: flex;
  justify-content: center;
  width: 2vw;
  height: 2vh;
}

.info_parameters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0px 0px 0px 5px;
}

.info_parameters__dimensions {
  padding: 4px 0px 3px 5px;
  font-weight: bold;
  font-size: 1rem;
}

.info_span {
  margin-right: 0.5em;
  margin-left: 0.5rem;
}

.config_info {
  padding: 1px 0px 0px 0px;
  font-size: 0.75rem;
}

.config_info__font {
  font-size: 0.75rem;
}

.typography_config {
  font-weight: bold;
  color: black;
  padding: 20px 0px 0px 5px;
}
.typography_config__div {
  display: flex;
  justify-content: flex-start;
}

.typography_threads {
  padding: 2px 0px 0px 2px;
  font-size: 0.75rem;
}

.noFile_font {
  font-weight: bold;
}

.typography_pdf {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 2px;
  font-size: 0.75rem;
  width: 200px;
  height: 30px;
}

.typography_pdf_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.typography_pdf__button {
  display: flex;
  justify-content: center;
  width: 2vw;
  height: 2vh;
}

.typography_comment {
  padding: 0px 0px 0px 2px;
  font-size: 0.75rem;
  font-weight: bold;
}

.typography_comment__p {
  width: 230px;
  height: 50px;
  border: 1px solid rgb(209, 207, 207);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 5px;
}

.typography_est_stock__div,
.typography_est_work__div {
  display: flex;
  justify-content: flex-start;
}

.typography_est_work__div {
  flex-direction: column;
  padding: 0px 0px 0px 1px;
  font-size: 0.75rem;
  width: 200px;
}

.typography_est_stock__div_font,
.typography_est_work__div_font {
  padding: 0px 0px 0px 1px;
  font-size: 0.75rem;
}

.typography_est_work__div_font {
  justify-content: space-between;
}

.sectionHeader {
  font-weight: bold;
  padding: 20px 0 0 0;
  margin: 0;
}

.paragraph,
.boldParagraph {
  margin: 0;
}

.boldParagraph {
  font-weight: bold;
}

.textTable {
  display: flex;
  justify-content: space-between;
  width: 220px;
}

.textColumn {
  display: flex;
  flex-direction: column;
}

.textRow {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.userModalContainer {
  margin: 1em 2.2em 2.2em 2em;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.userModalContainer::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.userModalContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.requiredText {
  font-weight: bold;
  opacity: 0.6;
  margin-bottom: 0;
}

.inputAdornment {
  margin-right: 18px;
}

.inputAdornmentIcon {
  color: var(--inputGray);
}

selector input[type="number"]::-webkit-outer-spin-button,
selector input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pd {
  padding: 1rem;
}

.pd2 {
  padding: 1.5rem;
}

.pdLeft {
  padding-left: 0.9rem;
}

.pdLeft1 {
  padding-left: 1rem;
}

.pdLeftRight {
  padding: 0 1rem 1rem 1rem;
}
.pdRight {
  padding-right: 1rem;
}

.pdRight50 {
  padding-right: 0.5rem;
}

.pdbottom {
  padding-bottom: 1rem;
}

.pdTop {
  padding-top: 1rem;
}

.pdRmv {
  padding: 0 !important;
}

.flexClm {
  display: flex;
  flex-direction: column;
}

.flexR {
  display: flex;
  flex-direction: row;
}

.flexRAlgn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexRMrg {
  display: flex;
  flex-direction: row;
  margin: 1rem;
}

.flRg {
  float: right;
}

.mrg {
  margin: 1rem;
}

.mrgTop {
  margin-top: 2em !important;
}

.mrrg {
  margin-right: 1em;
}

.mrgLeft {
  margin-left: 0.5em;
}

.containerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.modalCancelBtn {
  margin: 0 1em 0 0;
  height: 40px;
  width: 8em;
}

.modalAcceptBtn {
  margin: 0 0 0 1em;
  height: 40px;
  width: 8em;
}

.width6 {
  width: 6em;
}

.addressTitle {
  font-weight: bold;
  opacity: 0.6;
  margin-right: auto;
}

.smallText {
  font-weight: bold;
  opacity: 0.6;
}

.cardHeader {
  width: 6rem;
  border-radius: 3px;
  padding-left: 1rem;
}

.cardtitle {
  text-decoration: none;
  font-weight: 300;
  min-height: 32px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: var(--black);
  font-weight: bold;
}

.forgottenPassword {
  hyphens: auto;
  word-break: break-word;
}

.forgottenPassword:hover {
  cursor: pointer;
}

.card {
  color: var(--black);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: var(--cardBackground);
  box-shadow: 0 1px 4px 0 var(--shadow);
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
}

.loginCardWidth {
  width: 40em;
}

.cardDraftEdit {
  color: var(--black70);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: var(--cardBackground);
  margin-top: 30px;
  border-radius: 3px;
  margin-bottom: 30px;
  flex-direction: column;
}

.cardDraftEditDisabled {
  background: #bfdcdc;
}

.loginBtn {
  border-radius: 0px !important;
  max-height: 40px;
  width: 7.5rem;
  background: var(--header) !important;
  font-weight: bold;
  color: var(--white) !important;
  float: right;
}

.loginBtn:hover {
  color: var(--header);
  border: 1px solid var(--header);
  background: var(--white);
}

.draftCardsHeader {
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: var(--black70);
}

.txAlgn {
  text-align: center !important;
}

.headerBcg {
  background: var(--header);
}

.uploadBtn {
  background: var(--buttonYellow);
}

.loader {
  width: 6.5rem;
}

.loaderTd {
  display: flex;
  align-items: center;
  font-weight: 700;
  width: 8rem;
}

.loadingFileName {
  font-weight: 700;
  padding-left: 0.5rem;
}

.clrWhite {
  color: var(--white);
}

.draftTitle {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: x-large;
}

.draftBtnContainer {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 100000;
}

.draftBackBtn {
  border-radius: 0px !important;
  max-height: 40px;
  width: 10rem;
  background: var(--mainRed) !important;
  color: var(--white) !important;
}

.draftBackBtn:hover {
  color: var(--mainRed) !important;
  border: 1px solid var(--mainRed) !important;
  background: var(--white) !important;
}

.draftSaveBtn {
  border-radius: 0px !important;
  max-height: 40px;
  width: 10rem;
  background: var(--header) !important;
  color: var(--white) !important;
}

.draftSaveBtn:hover {
  color: var(--header) !important;
  border: 1px solid var(--header) !important;
  background: var(--white) !important;
}

.priorityContainer {
  display: flex;
  margin-top: 10px;
  padding-bottom: 20px;
  gap: 1em;
}

.tdContainer {
  color: var(--white);
  padding: 0;
  padding-left: 0.5rem;
}

.tdBtnContainer {
  padding-left: 1rem;
  display: flex;
  gap: 1em;
  align-items: center;
  color: var(--white);
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.opacity60 {
  opacity: 0.6px;
}

.dsplInBlock {
  display: inline-block;
}

.fontSize90 {
  font-size: 0.9rem;
}

.fontSize70 {
  font-size: 0.7rem;
}

.calcBoldTitle {
  color: #000;
  padding-left: 1rem;
  font-size: 1.5rem;
}

.calcTitle {
  opacity: 0.6;
  text-transform: uppercase;
}

.borderBottomNone {
  border-bottom: none;
}

.fntWeightBold {
  font-weight: bold;
}

.leftInputContainer {
  padding-right: 1rem;
  width: 13rem;
}

.rightInputContainer {
  width: 13rem;
}

.searchCompany {
  width: 27rem;
}

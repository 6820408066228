.dropzoneUpload {
  width: 90%;
  height: 500px;
  overflow: visible;
  transition: transform 0.2s;
}
.dropzoneUpload:hover {
  background: #00b28d;
}

.dropzoneContainer {
  height: 500px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzoneUploadText {
  margin-top: 2em;
  font-size: 32px;
}

.dropzoneChange {
  width: 90%;
  height: 200px;
  position: relative;
  transition: transform 0.2s;
}
.dropzoneChange:hover {
  background: #00b28d;
}

.dropzoneChangeText {
  margin-top: 75px;
  font-size: 32px;
}

.pdfTemp {
  height: 600px;
  position: relative;
  margin: 500px;
}

.pdfNavigation {
  display: flex;
  justify-content: center;
  margin: -12px 0 12px 0;
  position: relative;
}

.pdfPageText {
  text-align: center;
  padding: 13px 10px 0 10px;
}

.pdfDeleteButton {
  display: flex;
  flex-direction: column;
  float: right;
}

.fileResourceList {
  list-style: none;
  padding: 0;
}

.fileResourceListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.fileResourceListItem a {
  text-decoration: none;
  color: #000;
}

.fileResourceListItem a:hover {
  text-decoration: underline;
}

.fileResourceListItemDetails {
  color: #555;
  font-size: 0.9rem;
}

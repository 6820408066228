.hd__typo {
  font-size: 0.7rem !important;
  color: #c2c2c2 !important;
  border-bottom: none !important;
  padding: 0 !important;
}

.brd_bottom__rmv {
  border-bottom: none !important;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.container__header {
  min-width: 920px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  background: var(--header);
  color: #fff;
  height: 6vh;
}

.btn {
  border-radius: 2px;
  height: 40px;
  width: 9rem;
  font-weight: bold;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s; /* for smooth transition */
}

.btn__yellow {
  background: #e0a900;
}

.btn__yellow:hover {
  color: #e0a900;
  border: 1px solid #e0a900;
  background-color: transparent;
}

.btn__green {
  background: #51b27e;
  color: #fff;
}

.btn__green:hover {
  color: #51b27e;
  border: 1px solid #51b27e;
  background-color: transparent;
}

.container__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 150px;
  color: #fff;
  background-color: #075e5b;
}

.title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

/* product info */
.container__product {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
}

.container__product_details {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  /* line-height: 1.1; */
}

.container__product_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .product_details__id {
} */

.product_details__file {
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8em;
}

.container__icons {
  padding-left: 0.8rem;
}

.container__input_fields {
  /* width: 20rem; */
  display: flex;
  flex-direction: row;
}

.pd_top__05 {
  padding-top: 0.5rem;
}

.pd_top__2 {
  padding-top: 2rem;
}

.pd__05 {
  padding: 0.5rem;
}

.pd_top__2 {
  padding-top: 2rem;
}

.pd_rg__1 {
  padding-right: 1rem;
}

.input_field__separation {
  padding-right: 0.5rem;
}

.container_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.img__preview {
  border-radius: 4px;
  transition: transform 0.2s;
  margin: auto;
  display: block;
  width: 100%;
}

.img__preview {
  border-radius: 4px;
  transition: transform 0.2s;
  margin: auto;
  display: block;
  width: 100%;
}

/* MachineForm.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  padding: 16px;
  color: black;
  /* border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12); */
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.label {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
}

.input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  box-sizing: border-box; /* Add this property */
}

.input:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 0 1px #3f51b5;
}

.button {
  width: 100%;
  padding: 8px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #283593;
}

.button:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}

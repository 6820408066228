.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}

.showModal {
  display: flex;
}

.modalContent {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 30%;
  height: 80vh;
  max-height: 80vh;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: #000;
}

.modalHeader h2 {
  font-size: 24px;
  font-weight: bold;
}

.modalBody {
  height: inherit;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #000;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

label {
  font-weight: bold;
  font-size: 16px;
}

.inputField {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  font-size: 16px;
  background-color: #fff;
}

.textarea {
  height: 80px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.createButton {
  background-color: #32cd32;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.createButton:hover {
  background-color: #228b22;
}

.cancelButton {
  background-color: #ff4500;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancelButton:hover {
  background-color: #dc143c;
}

.openModalButton {
  background-color: var(--buttonYellow);
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.openModalButton:hover {
  background-color: #b58803;
}

.openModalButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.fileResourceTable {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 1rem;
}

.fileResourceTable tr {
  max-width: inherit;
}

.fileResourceTable th {
  text-align: left;
  font-weight: bold;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
}

.fileResourceTable td {
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
}

.fileResourceTable td:last-child {
  text-align: right;
}

.fileResourceTable input[type="file"] {
  display: block;
  margin-top: 0.5rem;
}

.fileResourceTable tbody {
  overflow: scroll;
}

.fileName {
  max-width: 15em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Common styles for initials and machine */
.initials,
.initials__square {
  display: flex;
  padding: 1px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #474949;
  transition: background 0.3s, color 0.3s, border 0.3s;
}

.initials {
  border-radius: 10px;
}

.initials__square {
  border-radius: 1px;
}

/* Complete styles */
.complete {
  color: white;
  border: 1px solid white;
}

.complete .initails__typography {
  color: white;
}

/* Hover styles */
.initials:hover,
.initials__square:hover {
  background: #075e5b;
  border: 1px solid #075e5b;
}

.initials:hover .initails__typography,
.initials__square:hover .initails__typography {
  color: #fff;
}

/* Typography styles */
.initails__typography {
  color: #474949;
  font-family: Gadugi;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* New hover styles */
.hoverRed:hover {
  background: #e12c4f;
  border: 1px solid #e12c4f;
}

.hoverRed:hover .initails__typography {
  color: white;
}

.hoverGreen:hover {
  background: #075e5b;
  border: 1px solid #075e5b;
}

.hoverGreen:hover .initails__typography {
  color: white;
}

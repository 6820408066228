.canvasContainer {
  margin-right: 1em;
  flex-grow: 1;
  border-radius: 5px;
  /* border: 1px solid #d0d0d0; */
  /* height: 40em !important; */
  /* width: 100%; */
  max-width: 66%;
}
.canvas {
  border-radius: 5px;
}
.threadContainer {
  position: relative;
  /* flex-grow: 1; */
  width: 33%;
  /*min-width: 33%; */
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 600px) {
  .scrollContainer {
    height: 100%;
    position: relative;
    overscroll-behavior: none;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .container {
    /* flex-direction: row; */
    /*height: 100%;*/
    /* width: 100%; */
    /* max-height: 40em; */
  }
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
  .canvasContainer {
    margin-right: 0;
    /* flex-grow: 1; */
    /* border-radius: 5px;
      border: 1px solid #d0d0d0; */
    /* height: 80em !important; */
    max-width: 100%;
  }
  .threadContainer {
    position: relative;
    /* flex-grow: 1; */
    width: 100%;
    /*min-width: 33%; */
  }
  .scrollContainer {
    height: 100%;
    position: relative;
  }
}

.container {
  display: flex;
  /* flex-direction: row; */
  height: 60vh;
  width: 100%;
}

.shadowContainer {
  position: absolute;
  right: 0;
  left: 0;
  pointer-events: none;
}

.shadowBottom {
  transition: 300ms;
  height: 50%;
  bottom: 0;
  -webkit-box-shadow: inset 0px -10px 10px -10px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px -10px 10px -10px rgba(0, 0, 0, 0.75);
}
.shadowTop {
  transition: 300ms;
  -webkit-box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.75);
  height: 50%;
  top: 0;
}

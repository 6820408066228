.title {
  opacity: 0.6;
  text-transform: uppercase;
}

.container {
  display: flex;
}

.container__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.3rem;
  width: 15vw;
}

.container__title {
  padding-left: 0.5rem;
  font-size: 0.9rem;
}

.content {
  display: flex;
  flex-direction: row;
}

.units {
  padding-top: 0.5rem;
  padding-left: 0.3rem;
}

/* total */
.total__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 3rem;
}

.total__title {
  padding-right: 3rem;
  font-weight: bold;
}

.total__price {
  margin-bottom: 0;
}

.total__price_pcs {
  font-size: 0.7rem;
}

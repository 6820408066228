.custom-calendar {
    width: 100%; /* Full width */
    max-width: 600px; /* Limit the width */
    /*margin: 0 auto; /* Center the calendar */
    font-size: 0.8rem; /* Increase the font size for better readability */
}

.custom-calendar p {
    margin: 0 0;
    font-weight: bold;
    font-size: 1.1rem; /* Increase the font size for better readability */
    text-align: center;
}

.custom-calendar .react-calendar__tile {
    border: #f0f0f0;
    /* margin: 1px; */
    border-color: #f0f0f0;
    padding: 4px;
    /* color: #000; */
}

.tile-content-wrapper {
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically align items */
    justify-content: center; /* Horizontally align items */
    height: 1.8rem; /* Increase the font size for better readability */
    width: 100%; /* Ensure it fills the tile */
}

.custom-calendar .react-calendar__navigation {
    margin: 0.3em;
    height: unset;
}

.custom-calendar .react-calendar {
    border: unset;
}

.workload-summary {
    display: flex;
    align-items: center;
    padding: 0px 0;
    font-size: 1rem;
    gap: 15px; /* Space between items */
}

.workload-summary span {
    display: flex;
    align-items: center;
    gap: 5px; /* Space between icon and text */
}
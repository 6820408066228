/* header */
.header {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;
  background: var(--header);
  color: var(--header);
  height: 6vh;
  align-items: center;
}

.headerTitleContainer {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}

.headerTitle {
  text-transform: uppercase;
  color: #fff;
  padding-left: 1rem;
}

.filterBtnContainer {
  display: flex;
  align-items: flex-start;
}

.searchContainer {
  padding-right: 1rem;
}

.tableContainer {
  padding: 0.5rem 4rem 0 4rem;
}

/* table header */
.title {
  padding-right: 0.5rem;
  font-size: 1rem;
  font-weight: 800;
}

.orderNum {
  font-size: 1rem;
  cursor: pointer;
  padding-right: 4.5rem;
  color: var(--header);
  font-weight: 800;
  text-decoration: none;
}

/* redundant but needed for override */
.orderNum:hover {
  font-size: 1rem;
  cursor: pointer;
  padding-right: 4.5rem;
  color: var(--header);
  font-weight: 800;
  text-decoration: underline;
}

.headerCell {
  border-bottom: 2px solid black !important;
  padding: 0 !important;
  line-height: 0;
}

.brBtm {
  border-bottom: 2px solid black !important;
}

.headerTxt {
  padding-right: 0.2rem;
  line-height: 0;
}

.headerCompleted {
  font-weight: bold;
  font-size: 1rem;
}

.headerNumContainer {
  font-size: 1.1rem;
}

.completedTasks {
  font-weight: 800;
}

/* table rows */
.modelCell {
  padding: 0 !important;
  max-width: 7em;
  border-bottom: 0;
}

.modelContainer {
  display: flex;
  flex-direction: column;
  line-height: 1;
  justify-content: center;
}

.modelId {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--black75);
}

.modelName {
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexC {
  display: flex;
  flex-direction: column;
}

.pd0 {
  padding: 0 !important;
}

.noBrdBottom {
  border-bottom: 0;
}

.quantityNum {
  font-size: 0.95rem;
  font-weight: bold;
  color: var(--black75);
}

.smallText {
  font-size: 0.6rem;
}

.capitalizeText {
  text-transform: capitalize;
}

.lowerText {
  text-transform: lowercase;
}

.matContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}

.dimContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
}

.matText {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--black75);
  text-transform: uppercase;
}

.titleText {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--black75);
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconContainer {
  padding-right: 0.5rem;
}

.stepIcon {
  fill: var(--black75) !important;
  cursor: pointer;
}

.pdfIcon {
  fill: var(--mainYellow);
  cursor: pointer;
}

.notActiveIcon {
  fill: var(--black10) !important;
}

.activeIcon {
  fill: var(--mainYellow);
}

.threadTooltipBkgr {
  background: var(--white);
}

.personIcon {
  cursor: pointer;
}

.chatPopup {
  position: absolute;
}

.finishTitle {
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--black75);
}

.assignContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

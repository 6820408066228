/* CompanySearch.module.css */
.searchInput {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.errorText {
  color: red;
}

.searchResults {
  position: absolute;
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 10;
}

.company {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  background-color: #fff;
}

.company:hover {
  background-color: #f0f0f0;
}

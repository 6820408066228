.tabs {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.tab {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  background: #e9f5f3;
  padding: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.tab__active {
  color: white; /* change to the color you want for the active tab */
  background: #e0a901;
}

.tab__name {
  text-transform: capitalize;
}

.tab__number {
  font-size: 2rem;
  padding: 0.5rem;
}

.titleContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -1em;
}

.title {
    color: var(--infoColor);
}

.iconWarning {
    animation: iconPulse 1.5s ease-in-out infinite;
}

@keyframes iconPulse {
    0% {
        transform: scale(1.0)
    }
    100% {
        transform: scale(1.8)
    }
}

.card {
    max-height: 200px;
    background-color: whitesmoke;
    overflow-x: hidden;
}

.card:hover {
    color: whitesmoke;
    background-color: var(--primaryColor);
    transition: 0.3s ease-in-out;
    transform: scale(1.02);
}

.card:not(:hover) {
    transition: 0.3s;
    transform: scale(1);
}


.inlineContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -0.4em;
}

.imageWarning {
    border-bottom: 3px solid orange;
}

.imageDanger {
    border-bottom: 3px solid var(--dangerColor);
}

.imageOkay {
    border-bottom: 3px solid var(--primaryColor);
}

.cardWarning {
    max-height: 200px;
    border: 4px solid orange;
    margin: -4px;
    overflow-x: hidden;
}

.cardWarning:hover {
    color: whitesmoke;
    background-color: orange;
    transition: 0.3s ease-in-out;
    transform: scale(1.01);
}

.cardWarning:hover .cardWarningText {
    color: whitesmoke;
    font-weight: bold;
}

.cardCritical {
    max-height: 200px;
    border: 4px solid var(--dangerColor);
    margin: -4px;
    animation: pulse 2s infinite;
    overflow-x: hidden;
}

.cardCritical:hover {
    color: whitesmoke;
    background-color: var(--dangerColor);
    transition: 0.3s ease-in-out;
    transform: scale(1.01);
}

.cardCritical:hover .cardCriticalText {
    color: whitesmoke;
    font-weight: bold;
}

.cardWarningText {
    color: orange;
    font-weight: bold;
}

.cardCriticalText {
    color: var(--dangerColor);
    font-weight: bold;
}

.tableRow:hover {
    background-color: whitesmoke;
}

@keyframes pulse {
    0% {
        transform: scale(1.02);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }

    100% {
        transform: scale(1.02);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}
:root {
  --lightMain: #7fbab9;
  --primaryColor: #00b28d;
  --infoColor: #26a1f3;
  --dangerColor: #f44336;
  --warningColor: #ef672f;
  --black: #212121;
  --header: #075e5b;
  --header-2: #075e5b;
  --darkGreen: #007574;
  --mainYellow: #e1a900;
  --buttonYellow: #e0a900;
  --mainRed: #e12c4f;
  --mainWhite: #eeeeee;
  --cardBackground: #e9f5f3;
  --inputGray: #555555;
  --white: #fff;
  --greyTaskBtn: #c2c2c2;
  --black75: #474949;
  --black10: #e7e7e7;
  --shadow: rgba(0, 0, 0, 0.14);
  --black70: rgba(0, 0, 0, 0.7);
  --black90: rgba(0, 0, 0, 0.9);
  --black25: #c2c2c2;
  --white: #fff;
  --iconGrey: #858585;
}

a {
  color: var(--primaryColor);
}

a:hover {
  font-weight: bold;
}

html,
body {
  margin: 0;
  height: 100%;
  color: var(--black);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  scrollbar-color: #ca9800 transparent;
}

.niceText {
  word-break: break-all;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/* Add margin to all table cells specifically within .tooldb__table */
.tooldb__table {
    border-collapse: collapse;
  }
  
  .tooldb__table th,
  .tooldb__table td {
    border: 1px solid black;
    padding: 8px; /* Adjust the padding to control the space inside */
  }
  
  /* Define the hover effect for tool rows (except for the heading row) within .tooldb__table */
  .tooldb__table tbody tr:hover {
    background-color: #f5f5f5; /* Change the background color to the desired shade */
    cursor: pointer;
  }
  
.container {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  border-radius: 2px;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.containerClickable {
  cursor: pointer;
}

.containerClickable:hover {
  background: #d8edeb;
}

.active {
  background: var(--primaryColor);
}

.name {
  font-weight: bold;
  font-size: 1rem;
}

.configuration {
  font-size: 1rem;
}

.leadtime {
  font-style: italic;
  font-size: 0.9rem;
}

.price {
  font-weight: bold;
  font-size: 1rem;
}

.unitPrice {
  font-size: 0.9rem;
  font-style: italic;
}

.previewImage {
  height: 72px;
  width: 108px;
  display: inline-block;
  background-color: #ccc;
}

.cellsNoWrap {
  white-space: nowrap;
}

.cellItemDescription {
  width: 60%;
}

@media screen and (max-width: 1000px) {
  .name,
  .configuration,
  .price {
    font-size: 0.8rem;
  }

  .leadtime,
  .unitPrice {
    font-size: 0.7rem;
  }

  .previewImage {
    height: 36px;
    width: 54px;
  }
}

.modalHeader {
  color: var(--black);
  font-weight: bold;
  margin-bottom: -5px;
  padding-left: 1rem;
  opacity: 0.8;
  text-transform: uppercase;
}

.pd {
  padding: 1.5rem;
}

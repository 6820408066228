.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown__item:hover {
  color: #e1a901;
}

/* Assign Users */
.container__initials {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.button_icon {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
}

/* both */
.icon_hover {
  color: #075e5b;
  height: 0.9em !important;
}

.icon {
  color: #8e8e8e;
  height: 0.9em !important;
}

/* Dropdown container styles */
.dropdown_container {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 140px;
  overflow: auto;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 9999;
}

/* Dropdown item styles */
.dropdown_item {
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 0.7rem;
  transition: background-color 0.3s;
  text-align: left;
}

.item_unselected {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
}

/* Dropdown item when selected */
.item_selected {
  color: white;
  background-color: #075e5b;
}

.hovered_list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: transparent;
  cursor: pointer;
}
